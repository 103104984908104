
import {
  Component,
  Vue,
  PropSync,
  Prop,
} from 'vue-property-decorator';
import { DatePickerProps } from '@/types/vjsf';

@Component({
  name: 'DatePicker',
})
export default class DatePicker extends Vue {
  @PropSync('dateTimeString', { required: true })
  private dateTime!: string|null;

  @Prop({ required: true })
  private readonly options!: DatePickerProps | undefined;

  @Prop({ required: true })
  private readonly format!: string;

  get date(): string {
    if (this.dateTime === null) {
      return '';
    }
    if (this.dateTime?.includes('-')) {
      return this.dateTime.split('T')[0];
    }
    return '';
  }

  set date(input: string) {
    switch (this.format) {
      case 'date':
        this.dateTime = input;
        break;
      default:
        if (this.dateTime !== null && this.dateTime?.includes('T')) {
          this.dateTime = `${input}T${this.dateTime.split('T')[1]}`;
        } else {
          this.dateTime = `${input}T00:00Z`;
        }
        break;
    }
  }
}
